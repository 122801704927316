/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useFormik } from 'formik';
import TextField from 'components/Core/TextField';
import Select from 'components/Core/Select';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import Button from 'components/Core/Button';
import { getFormValid } from 'utils/formUtils';
import { LRCSteps } from '../styles';
import { BillableHourList } from '../utils/content';

export const StepBillableHours = (props) => {
  const checkValidate = (value) => value !== '' && value >= 0;

  const formik = useFormik({
    initialValues: {
      holidaysPerTech: props.values.holidaysPerTech,
      vacationDaysPerTech: props.values.vacationDaysPerTech,
      billableHourEfficiency: props.values.billableHourEfficiency,
    },
    validate: (values) => {
      return {
        ...(!checkValidate(values.holidaysPerTech) && {
          holidaysPerTech: 'Holidays per Tech is invalid',
        }),
        ...(!checkValidate(values.vacationDaysPerTech) && {
          vacationDaysPerTech: 'Vacation day per Tech is invalid',
        }),
        ...(!checkValidate(values.billableHourEfficiency) && {
          billableHourEfficiency: 'Billable hour efficiency is invalid',
        }),
      };
    },
    onSubmit: (values) => {
      props.onClickNext(values);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(
      id,
      id !== 'billableHourEfficiency' ? parseFloat(newValue.replace(/\$|,/g, '')) : newValue,
    );
  };

  const onHandleKeyPressed = (event) => {
    if (event.key === 'Enter') {
      formik.submitForm();
    }
  };

  const onClickBack = () => {
    if (props.onClickBack) {
      props.onClickBack();
    }
  };

  const formValid = getFormValid({ ...formik });

  return (
    <LRCSteps>
      <FormLabelWithField
        formControlProps={{ className: 'lrc-step--field-question-control' }}
        className="lrc-step--field-question"
        label="Average Holidays per Tech per year?"
        isRequired={false}
      />
      <TextField
        className="lrc-step--field-text"
        id="holidaysPerTech"
        type="number"
        placeholderText="Amount of days"
        values={formik.values}
        formValid={formValid}
        formErrors={formik.errors}
        handleChange={onHandleChange}
        handleKeyPress={onHandleKeyPressed}
        decimalScale={0}
        autoFocus
      />
      <FormLabelWithField
        formControlProps={{ className: 'lrc-step--field-question-control' }}
        className="lrc-step--field-question"
        label="Average Vacation days per Tech per year?"
        isRequired={false}
      />
      <TextField
        className="lrc-step--field-text"
        id="vacationDaysPerTech"
        type="number"
        placeholderText="Amount of days"
        values={formik.values}
        formValid={formValid}
        formErrors={formik.errors}
        handleChange={onHandleChange}
        handleKeyPress={onHandleKeyPressed}
        decimalScale={0}
      />
      <FormLabelWithField
        formControlProps={{ className: 'lrc-step--field-question-control' }}
        className="lrc-step--field-question"
        label="What is your average billable hour efficiency?"
        helperText="Statistic used to measure and track technician’s sold hours.  Industry average is under 50%."
        isRequired={false}
      />
      <Select
        className="lrc-step--field-select"
        id="billableHourEfficiency"
        placeholder="Select percentage"
        options={BillableHourList}
        values={formik.values}
        formValid={formValid}
        handleChange={onHandleChange}
      />
      <div className="lrc-step--button-group">
        <Button
          className="lrc-step--button-back"
          type="Transparent - Black Text - No Border"
          shadow={false}
          onDebouncedClick={onClickBack}
        >
          Back
        </Button>
        <Button
          className="lrc-step--button-next"
          type="secondary"
          shadow={false}
          onDebouncedClick={() => formik.submitForm()}
        >
          Continue
        </Button>
      </div>
    </LRCSteps>
  );
};
