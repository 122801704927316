import styled from 'styled-components';

export const StepContainer = styled.div`
  max-width: 1160px;
  margin: 45px auto 65px;

  @media (max-width: 575px) {
    margin: 18px auto 40px;
  }
`;
