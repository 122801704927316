/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from 'components/Core/TextField';
import Button from 'components/Core/Button';
import useBusinessCalcImages from 'hooks/useBusinessCalcImages';
import { getFormValid } from 'utils/formUtils';
import { LRCSteps, ResultModal } from '../styles';

export const StepCalculating = (props) => {
  const pageImages = useBusinessCalcImages();
  const calcGif =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'BusinessCalc Calculating')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';
  const resultImg =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'BusinessCalc Result')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  const [results, setResults] = useState({
    projectedBillableHoursPerTech: 0,
    coverOverheadOnly: 0,
    breakEvenRate: 0,
    billableLaborRate: 0,
  });

  const [otherModal, setOtherModal] = useState(false);

  const showOtherModal = () => {
    setOtherModal(true);
  };

  const handleCancelOtherModal = () => {
    setOtherModal(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Email is invalid').required('Email is required'),
    }),
    onSubmit: (values) => {
      props.onSubmit(values, results);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
  };

  const onHandleKeyPressed = (event) => {
    if (event.key === 'Enter') {
      formik.submitForm();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      showOtherModal();
    }, 4000);

    const tmpValues = { ...results };
    const totalWeeks = 52;
    const workingHoursPerWeek = 40;
    const availableWorkingHours = parseFloat(
      totalWeeks * workingHoursPerWeek -
        (props?.values?.holidaysPerTech + props?.values?.vacationDaysPerTech) * 8,
    );
    tmpValues.projectedBillableHoursPerTech = parseFloat(
      (availableWorkingHours * props?.values?.billableHourEfficiency) / 100,
    );
    tmpValues.coverOverheadOnly = parseFloat(
      props?.values?.overhead /
        (props?.values?.techCount * tmpValues.projectedBillableHoursPerTech),
    );
    tmpValues.breakEvenRate = parseFloat(
      props?.values?.hourlyRatePerTech + tmpValues.coverOverheadOnly,
    );
    tmpValues.billableLaborRate = parseFloat(
      (tmpValues.breakEvenRate * 100) / (100 - props?.values?.netProfit),
    );
    setResults(tmpValues);
  }, []);

  const formValid = getFormValid({ ...formik });

  return (
    <LRCSteps>
      <img className="lrc-step--field-img calc-gif" src={calcGif} />
      <p className="lrc-step--field-description calc-description">Calculating Labor Rate...</p>
      <ResultModal
        open={otherModal}
        handleClose={handleCancelOtherModal}
        onBackdropClick={handleCancelOtherModal}
        width="520px"
      >
        <div className="calc-result-modal">
          <p className="lrc-step--field-description calc-result-title font-heading-lg">
            Labor Rate Calculated
          </p>
          <img className="lrc-step--field-img calc-result-img" src={resultImg} />
          <p className="lrc-step--field-description calc-result-description">
            Where would you like us to send a copy of your report?
          </p>
          <TextField
            className="lrc-step--field-text"
            id="email"
            type="text"
            nameType="Email"
            placeholderText="Enter Business Email Address"
            values={formik.values}
            formValid={formValid}
            formErrors={formik.errors}
            handleChange={onHandleChange}
            handleKeyPress={onHandleKeyPressed}
            autoFocus
          />
          <Button
            className="lrc-step--button-next"
            type="primary"
            shadow={false}
            onDebouncedClick={() => formik.submitForm()}
          >
            Send Report
          </Button>
        </div>
      </ResultModal>
    </LRCSteps>
  );
};
