/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useFormik } from 'formik';
import TextField from 'components/Core/TextField';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import Button from 'components/Core/Button';
import { getFormValid } from 'utils/formUtils';
import { LRCSteps } from '../styles';

export const StepOverhead = (props) => {
  const formik = useFormik({
    initialValues: {
      overhead: props.values.overhead,
    },
    validate: (values) => {
      if (!values.overhead || values.overhead <= 0) {
        return { overhead: 'Overhead is invalid' };
      }
      return {};
    },
    onSubmit: (values) => {
      props.onClickNext(values);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, parseFloat(newValue.replace(/\$|,/g, '')));
  };

  const onHandleKeyPressed = (event) => {
    if (event.key === 'Enter') {
      formik.submitForm();
    }
  };

  const onClickBack = () => {
    if (props.onClickBack) {
      props.onClickBack();
    }
  };

  const formValid = getFormValid({ ...formik });

  return (
    <LRCSteps>
      <FormLabelWithField
        formControlProps={{ className: 'lrc-step--field-question-control' }}
        className="lrc-step--field-question"
        label="What were your overhead expenses last year?"
        isRequired={false}
      />
      <p className="lrc-step--field-description">
        This includes expenses that cannot be directly tied to a job. Overhead expenses include
        rent, utilities, office salaries, etc. Do not include any expenses such as interest, taxes,
        vehicle depreciation, or amortization costs.
      </p>
      <TextField
        className="lrc-step--field-text"
        id="overhead"
        type="currency"
        placeholderText="Enter Amount"
        values={formik.values}
        formValid={formValid}
        formErrors={formik.errors}
        handleChange={onHandleChange}
        handleKeyPress={onHandleKeyPressed}
        decimalScale={0}
        autoFocus
      />
      <div className="lrc-step--button-group">
        <Button
          className="lrc-step--button-back"
          type="Transparent - Black Text - No Border"
          shadow={false}
          onDebouncedClick={onClickBack}
        >
          Back
        </Button>
        <Button
          className="lrc-step--button-next"
          type="secondary"
          shadow={false}
          onDebouncedClick={() => formik.submitForm()}
        >
          Continue
        </Button>
      </div>
    </LRCSteps>
  );
};
