export const BillableHourList = [
  { label: 'Select percentage', value: '' },
  { label: '10%', value: '10' },
  { label: '30%', value: '30' },
  { label: '50%', value: '50' },
  { label: '70%', value: '70' },
  { label: '90%', value: '90' },
  { label: 'Higher than 90%', value: '91' },
];

export const ProfitList = [
  { label: 'Select percentage', value: '' },
  { label: '10% Net Profit', value: '10' },
  { label: '25% Net Profit', value: '25' },
  { label: '30% Net Profit', value: '30' },
  { label: 'Above 30% Net Profit', value: '31' },
];
