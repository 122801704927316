/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useFormik } from 'formik';
import Select from 'components/Core/Select';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import Button from 'components/Core/Button';
import { getFormValid } from 'utils/formUtils';
import { LRCSteps } from '../styles';
import { ProfitList } from '../utils/content';

export const StepNetProfit = (props) => {
  const formik = useFormik({
    initialValues: {
      netProfit: props.values.netProfit,
    },
    validate: (values) => {
      if (!values.netProfit || values.netProfit === '') {
        return { netProfit: 'Net Profit is invalid' };
      }
      return {};
    },
    onSubmit: (values) => {
      props.onClickNext(values);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
  };

  const onClickBack = () => {
    if (props.onClickBack) {
      props.onClickBack();
    }
  };

  const formValid = getFormValid({ ...formik });

  return (
    <LRCSteps>
      <FormLabelWithField
        formControlProps={{ className: 'lrc-step--field-question-control' }}
        className="lrc-step--field-question"
        label="What is your desired net profit?"
        isRequired={false}
      />
      <p className="lrc-step--field-description">
        Net Profit is your Profit divided by your total sales. 20% is considered a good starting
        point.
      </p>
      <Select
        className="lrc-step--field-select"
        id="netProfit"
        placeholder="Select percentage"
        options={ProfitList}
        values={formik.values}
        formValid={formValid}
        handleChange={onHandleChange}
      />
      <div className="lrc-step--button-group">
        <Button
          className="lrc-step--button-back"
          type="Transparent - Black Text - No Border"
          shadow={false}
          onDebouncedClick={onClickBack}
        >
          Back
        </Button>
        <Button
          className="lrc-step--button-next"
          type="secondary"
          shadow={false}
          onDebouncedClick={() => formik.submitForm()}
        >
          Continue
        </Button>
      </div>
    </LRCSteps>
  );
};
