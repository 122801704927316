import React, { useState, useEffect } from 'react';
import { createHistory } from '@reach/router';
import { navigate, graphql } from 'gatsby';
import { formSubmittedEvent } from 'services/dataLayerService';
import SEO, { createSEO } from 'components/Core/SEO';
import Steps from 'components/Core/Steps';
import { MultiStepDemoHeader } from 'components/Form/MultiStepDemoForm/MultiStepDemoHeader';
import FadeTransitionRouter from 'components/Tools/FadeTransitionRouter';
import { StepOverhead } from 'components/Tools/LaborRateCalculator/StepOverhead';
import { StepTechCount } from 'components/Tools/LaborRateCalculator/StepTechCount';
import { StepBillableHours } from 'components/Tools/LaborRateCalculator/StepBillableHours';
import { StepHourlyRate } from 'components/Tools/LaborRateCalculator/StepHourlyRate';
import { StepNetProfit } from 'components/Tools/LaborRateCalculator/StepNetProfit';
import { StepCalculating } from 'components/Tools/LaborRateCalculator/StepCalculating';
import { init, saveForm } from 'services/marketoService';
import { getSetCookies } from 'utils/cookieUtility';
import { convertToolResultToParams, removeLastSlash } from 'utils/urlHelper';
import { StepContainer } from './styles';
import './style.less';

const initValues = {
  industry: '',
  otherIndustry: '',
  businessFocus: '',
  company: '',
  overhead: '',
  techCount: '',
  holidaysPerTech: '',
  vacationDaysPerTech: '',
  billableHourEfficiency: '',
  hourlyRatePerTech: '',
  netProfit: '',
};

const LaborRateCalcStep = (props) => {
  const { pagePrefix } = props.pageContext;
  const pathArray = removeLastSlash(props?.location?.pathname).split('/');
  const pathSlug = pathArray?.[pathArray?.length - 1];
  const marketoFormId = 2814;
  const initEmailValue = props.location.state ? props.location.state.valuefromSubscribeForm : null;
  const [multiStepDemoIndex, setMultiDemoIndex] = useState(0);
  const [values, setValues] = useState(initValues);
  const [isPrevNext, setPrevNext] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const seo = createSEO(props?.data?.contentfulSeo, props);

  const onBack = () => {
    if (isTransitioning) {
      return;
    }

    setPrevNext(0);
    setMultiDemoIndex((prevStepIndex) => prevStepIndex - 1);
  };

  useEffect(() => {
    init(marketoFormId);
    const tempValues = getSetCookies(values);
    setValues(tempValues);

    const history = createHistory(window);
    const backListener = history.listen((location) => {
      if (location.action === 'POP') {
        onBack();
      }
    });

    return () => {
      backListener();
    };
  }, []);

  const onNext = (newValues) => {
    if (isTransitioning) {
      return;
    }

    const tmpValues = { ...values, ...newValues };
    setValues(tmpValues);
    setPrevNext(1);
    setMultiDemoIndex((prevStepIndex) => prevStepIndex + 1);
  };

  const convertResultParams = (results) => {
    return convertToolResultToParams([
      results.overhead,
      results.techCount,
      results.holidaysPerTech,
      results.vacationDaysPerTech,
      results.billableHourEfficiency,
      results.hourlyRatePerTech,
      results.netProfit,
      results.projectedBillableHoursPerTech,
      results.coverOverheadOnly,
      results.breakEvenRate,
      results.billableLaborRate,
    ]);
  };

  const fetchMarketoData = async (data) => {
    await saveForm({
      ...data,
      marketoFormId,
    });

    navigate(`/${pagePrefix}-results?${convertResultParams(data)}`);
  };

  const onSubmit = async (newValues, results) => {
    const updatedValues = { ...values, ...newValues, ...results };

    updatedValues.billableHourEfficiency = parseFloat(updatedValues.billableHourEfficiency).toFixed(
      0,
    );
    updatedValues.hourlyRatePerTech = parseFloat(updatedValues.hourlyRatePerTech).toFixed(2);
    updatedValues.projectedBillableHoursPerTech = parseFloat(
      updatedValues.projectedBillableHoursPerTech,
    ).toFixed(2);
    updatedValues.breakEvenRate = parseFloat(updatedValues.breakEvenRate).toFixed(2);
    updatedValues.billableLaborRate = parseFloat(updatedValues.billableLaborRate).toFixed(2);
    updatedValues.coverOverheadOnly = parseFloat(updatedValues.coverOverheadOnly).toFixed(2);

    setValues(updatedValues);

    const resultUrl = `${props.location.origin}/${pagePrefix}-results?${convertResultParams(
      updatedValues,
    )}`;

    Bizible.Push('User', {
      eMail: updatedValues.email, // required
    });

    const dataLayerFields = {
      industry: updatedValues.industry,
      other_industry: updatedValues.industry === 'Other' ? updatedValues.otherIndustry : '',
      businessFocus: updatedValues.businessFocus,
      company: updatedValues.company,
      overhead: updatedValues.overhead,
      no_of_technicians: updatedValues.techCount,
      holidaysPerTech: updatedValues.holidaysPerTech,
      vacationDaysPerTech: updatedValues.vacationDaysPerTech,
      billableHourEfficiency: updatedValues.billableHourEfficiency,
      hourlyRatePerTech: updatedValues.hourlyRatePerTech,
      netProfit: updatedValues.netProfit,
      email: updatedValues.email,
      projectedBillableHoursPerTech: updatedValues.projectedBillableHoursPerTech,
      coverOverheadOnly: updatedValues.coverOverheadOnly,
      breakEvenRate: updatedValues.breakEvenRate,
      billableLaborRate: updatedValues.billableLaborRate,
      utm_source: updatedValues.utm_source,
      utm_medium: updatedValues.utm_medium,
      utm_campaign: updatedValues.utm_campaign,
      utm_content: updatedValues.utm_content,
      utm_term: updatedValues.utm_term,
      utm_promo: updatedValues.utm_promo,
      gclid: updatedValues.gclid,
      creative: updatedValues.creative,
      keyword: updatedValues.keyword,
      matchtype: updatedValues.matchtype,
      latestResultsLink: resultUrl,
    };

    formSubmittedEvent({ form_type: 'Labor Rate Calculator', ...dataLayerFields });

    dataLayer.push({
      event: 'calculator',
      calculator_type: 'Labor Rate Calculator',
      ...dataLayerFields,
    });
    await fetchMarketoData({ ...updatedValues, latestResultsLink: resultUrl });
  };

  const incompleteSubmit = () => {
    navigate(`/${pagePrefix}`);
  };

  useEffect(() => {
    const { search } = props.location;
    if (multiStepDemoIndex === -1) {
      navigate(`/${pagePrefix}${search}`);
    } else if (multiStepDemoIndex === 0) {
      navigate(`/${pagePrefix}/overhead${search}`);
    } else if (multiStepDemoIndex === 1) {
      navigate(`/${pagePrefix}/tech-count${search}`);
    } else if (multiStepDemoIndex === 2) {
      navigate(`/${pagePrefix}/billable-hours${search}`);
    } else if (multiStepDemoIndex === 3) {
      navigate(`/${pagePrefix}/hourly-rate${search}`);
    } else if (multiStepDemoIndex === 4) {
      navigate(`/${pagePrefix}/net-profit${search}`);
    } else if (multiStepDemoIndex === 5) {
      navigate(`/${pagePrefix}/calculating${search}`);
    }
  }, [multiStepDemoIndex]);

  useEffect(() => {
    if (initEmailValue) {
      setValues((prevValues) => ({ ...prevValues, email: initEmailValue }));
    }
  }, []);

  return (
    <>
      <SEO {...seo} />
      <div className="bc-step--main-container data-layer-form">
        <MultiStepDemoHeader gobackPage={incompleteSubmit} />
        <StepContainer>
          <Steps
            steps={['Overhead', 'Tech Count', 'Billable Hours', 'Hourly Rate', 'Net Profit']}
            activeStep={multiStepDemoIndex}
          />

          <FadeTransitionRouter
            stepIndex={multiStepDemoIndex}
            isPrevNext={isPrevNext}
            setIsTransitioning={setIsTransitioning}
          >
            {pathSlug === 'overhead' && (
              <StepOverhead
                onClickBack={onBack}
                onClickNext={onNext}
                values={values}
                path={`${pagePrefix}/:page`}
              />
            )}
            {pathSlug === 'tech-count' && (
              <StepTechCount
                onClickBack={onBack}
                onClickNext={onNext}
                values={values}
                path={`${pagePrefix}/:page`}
              />
            )}
            {pathSlug === 'billable-hours' && (
              <StepBillableHours
                onClickBack={onBack}
                onClickNext={onNext}
                values={values}
                path={`${pagePrefix}/:page`}
              />
            )}
            {pathSlug === 'hourly-rate' && (
              <StepHourlyRate
                onClickBack={onBack}
                onClickNext={onNext}
                values={values}
                path={`${pagePrefix}/:page`}
              />
            )}
            {pathSlug === 'net-profit' && (
              <StepNetProfit
                onClickBack={onBack}
                onClickNext={onNext}
                values={values}
                path={`${pagePrefix}/:page`}
              />
            )}
            {pathSlug === 'calculating' && (
              <StepCalculating
                onSubmit={onSubmit}
                onClickNext={onNext}
                values={values}
                path={`${pagePrefix}/:page`}
              />
            )}
          </FadeTransitionRouter>
        </StepContainer>
      </div>
    </>
  );
};

export default LaborRateCalcStep;

export const LaborRateCalcStepPageQuery = graphql`
  query LaborRateCalcStepPageQuery($internalName: String!) {
    contentfulSeo(internalName: { eq: $internalName }) {
      ...Seo
    }
  }
`;
